import {
    get,
    post,
    put,
    formDataPost,
    formDataPut
} from "../ApiDecorator"; // 倒入 api

const Kimi = {
    // 获取kimi结果
    getReportResult(data) {
        return get("/course/ai/getReportResult", data);
    },
    // kimi创建新会话 
    newTab(data) {
        return get("/course/ai/newTab", data)
    },
    // kimi获取消息列表 
    msgList(data) {
        return get("/course/ai/msgList", data)
    },
    // kimi获取会话列表 
    getReportList(data) {
        return get("/course/ai/getReportList", data)
    },
    // kimi发送聊天消息 
    sentMsg(data) {
        return post("/course/ai/sentMsg", data)
    },
    // 上传文件到kimi 
    uploadFileToKiMi(data){
        return formDataPost("/course/report/uploadFileToKiMi",data)
    },
    // 观思问列表 /phase/phaseUserList
    phaseUserList(data){
        return get("/course/phase/phaseUserList",data)
    },
    // 观思问详情 
    userTrainingList(data){
        return get("/course/phase/userTrainingList",data)
    },
    // 导出观思问列表 
    exportGsw(data){
        return get("/course/phase/export",data)
    },
    // kimi获取学员列表 
    reportUserList(data){
        return get("/course/ai/reportUserList",data)
    },
    // kimi获取历史记录列表 
    msgHistoryList(data){
        return get("/course/ai/msgHistoryList",data)
    },
    // kimi获取单个历史记录详情 
    msgListOne(data){
        return get("/course/ai/msgListOne",data)
    },
    // 编辑kimi生成的报告 
    editRreport(data){
        return formDataPut('/course/ai/editRreport',data)
    },
    // 获取kimi生成的报告
    reportAiLast(data){
        return get('/course/ai/reportAiLast',data)
    },
    // 报告管理 查询报告状态200且true 
    getStatus(data){
        return get('/report/getStatus',data)
    }
}

export default Kimi;

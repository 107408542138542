import {
  get,
  put,
  patch,
  post,
  fetch,
  del,
  Delete,
  formDataPost
} from "../ApiDecorator"; // 倒入 api
/* 将所有接口统一起来便于维护
 * 如果项目很大可以将 url 独立成文件，接口分成不同的模块
 * 此处的数据依然来自 Easy Mock
 */

// 单独倒出
const project = {
  // 获取直播间url
  live(data) {
    return fetch("/course/teach/lesson/goLesson/", data);
  },
  // 获取项目主页项目简报
  getProjectBriefing(data) {
    return get("/learn/project/projectBriefing", data);
  },
  // 获取项目文件
  getProjectFile(data) {
    return get("/learn/project/projectFile", data);
  },
  // 获取优秀作业
  getExcellentWork(data) {
    return get("/learn/project/projectExcellentHomework", data);
  },
  // 获取项目排行榜
  getProjectRanking(data) {
    return get("/learn/project/projectRanking", data);
  },
  // 获取项目成果
  getPreojectResult(data) {
    return get("/learn/project/projectAchievementDisplay", data);
  },
  // 获取项目内答疑列表Id
  getDiscussionId(data) {
    return get("/learn/project/discussionId", data);
  },
  // 获取精品课程列表
  getListBoutique(data) {
    return get("/course/teach/course/listBoutique", data);
  },
  // 获取学情统计数据
  getStatistics(data) {
    return get("/score/projectHome/getStatistics", data);
  },
  // 获取项目文件列表 （管理员）
  getProjectFileList(data) {
    return get("/learn/project/projectFileList", data);
  },
  // 添加项目文件 （管理员）
  addProjectFile(data) {
    return post("/learn/project/addProjectFile", data);
  },
  // 删除项目文件 （管理员）
  deleteProjectFile(data) {
    return Delete("/learn/project/deleteProjectFile", data);
  },
  // 编辑项目文件 （管理员）
  editProjectFile(data) {
    return put("/learn/project/editProjectFile", data);
  },
  // 查看项目文件 （管理员）
  selectProjectFile(data) {
    return get("/learn/project/selectProjectFile", data);
  },
  // 查看项目下课程学习数据 （管理员）
  getCourseStudyInfo(data) {
    return get("/score/projectHome/getCourseStudyInfo", data);
  },
  // 查看课程下学员详情数据 （管理员）
  getUserStudyInfo(data) {
    return get("/score/projectHome/getUserStudyInfo", data);
  },
  // 导出学员详情数据 （管理员）
  // exportUserStudyInfo(data) {
  //   return get("/score/projectHome/getUserStudyInfo/export", data);
  // },
  // 查看项目下人员统计 （管理员）
  getUserStatisticInfo(data) {
    return get("/score/projectHome/getUserStatisticInfo", data);
  },
  // 导出人员统计数据 （管理员）
  exportUserStatisticInfo(data) {
    return get("/score/projectHome/getUserStatisticInfo/export", data);
  },
  // 查询作业统计数据 （管理员）
  getWorkStatisticList(data) {
    return get("/quest/project/list", data);
  },
  // 导出作业统计数据 （管理员）
  exportWorkStatisticInfo(data) {
    return get("/quest/project/list/export", data);
  },
  // 导出课程统计数据 （管理员）
  exportCourseStatisticInfo(data) {
    return get("/score/projectHome/getCourseStudy/export", data);
  },

  // 查询教师考勤列表
  getAttendanceByTeacher(data) {
    return get("/learn/attendance/listByTeacher", data)
  },
  // 查询单个考勤
  getAttendanceOne(data) {
    return get("/learn/attendance/attendanceOne", data)
  },
  // 编辑考勤状态
  editAattendance(data) {
    return put("/learn/attendance/editAattendance", data);
  },
  // 查询教师健康列表
  getHealthByTeacher(data) {
    return get("/learn/health/listByTeacher", data)
  },
  // 查询单个健康情况
  getHealthOne(data) {
    return get("/learn/health/healthOne", data)
  },
  // 编辑健康状态
  editHealth(data) {
    return put("/learn/health/editHealth", data)
  },
  // 查询刷新状态
  checkRefresh(data) {
    return get("/score/projectHome/getRefreshCourseStatus", data)
  },
  // 通知后台刷新数据
  sendRefresh(data) {
    return get("/score/projectHome/refreshCourseStudyInfo", data)
  },
  // 获取导出作业地址
  getExportWorkUrl(data) {
    return get("/quest/project/list/exportCreate", data)
  },
  // 获取导出作业状态
  exportWork(data) {
    return get("/quest/project/download/exportStatus", data)
  },
  // 习题管理- 查询题库列表
  getExamQuestList(data) {
    return get("/quest/qb/listAdmin", data)
  },
  // 习题管理- 获取筛选条件
  getExamQuestFilter(data) {
    return get("/quest/qb/listAdminConditions", data)
  },
  // 习题管理- 导入题库
  importQuestZip(data) {
    return formDataPost("/quest/qb/importQuestZip", data)
  },
  // 习题管理- 获取下载模板id
  getTemplate(data) {
    return get("/quest/qb/getTemplate", data)
  },
  // 习题管理- 删除项目下题目
  deleteQuestion(data) {
    return Delete("/quest/qb/deleteQuestion", data)
  },
  // 习题管理- 导入删除表(批量删除)
  deleteExportQuests(data) {
    return formDataPost("/quest/qb/deleteExport", data)
  },
  // 习题管理 - 导出题库题目
  exportQbQuest(data) {
    return get("/quest/qb/export/qbQuest", data)
  },
  // 习题管理 - 获取导入题库的状态
  getIimportState(data) {
    return get("/quest/qb/getImportState", data)
  },
  // 活动管理（管理员）- 获取活动管理列表
  getTotalActivities(data) {
    return get("course/activity/projectManageActivity", data)
  },
  // 项目课管理（管理员）- 获取项目课管理列表
  getTotalCourse(data) {
    return get("course/teach/course/listForAdmin", data)
  },
  // 项目课管理（管理员）- 获取作业管理列表
  getTotalWorks(data) {
    return get("/quest/work/selectThemeListByAdmin", data)
  },
  // 考勤管理（管理员）- 获取考勤统计列表
  getAttendanceList(data) {
    return get("/learn/attendance/listByAdmin", data)
  },
  // 考勤管理（管理员）- 获取考勤统计详情
  getAttendanceListDate(data) {
    return get("/learn/attendance/listAdminByDate", data)
  },
  // 考勤管理（管理员）-  获取 按人导出/详情导出 下载地址
  attendanceExportPath(data) {
    return get("/learn/attendance/listAdminByDate/getPath", data)
  },
  // 考勤管理（管理员）-  查询状态 按人导出/详情导出 
  attendanceExportState(data) {
    return get("/learn/attendance/listAdminByDate/getState", data)
  },
  // 优秀作业管理（管理员）- 获取优秀作业排行榜
  projectWorkRanking(data) {
    return get("/learn/project/projectRanking", data)
  },
  // 优秀作业管理（管理员）- 获取个人优秀作业详情
  projectWorkRankingUser(data) {
    return get("/learn/project/projectRankingUser", data)
  },
  // 研讨沙龙管理 (管理员) - 获取沙龙列表
  getToatlSalonList(data) {
    return get("/seminar/salon/listForAdmin", data)
  },
  // 三段九环 报告管理(管理员) - 获取报告列表
  getReportList(data) {
    return get("/course/report/reportList", data)
  },
  // 三段九环 报告管理(管理员) - 获取创建报告的下拉列表
  getReportOption(data) {
    return get("/course/report/reportOption", data)
  },
  // 三段九环 报告管理(管理员) - 发布
  getReportPublish(data) {
    return get("/course/report/reportPublish", data)
  },
  // 三段九环 报告管理(管理员) - 删除
  delReport(data) {
    return Delete("/course/report/reportDelete", data)
  },
  // 三段九环 报告管理(管理员) - 创建
  createReport(data) {
    return post("/course/report/reportCreate", data)
  },
  // 三段九环 报告管理(管理员) - 查询报告信息
  queryReport(data) {
    return get("/course/report/reportOne", data)
  },
  // 三段九环 报告管理(管理员) - 编辑
  editReport(data) {
    return post("/course/report/reportEdit", data)
  },
  // 三段九环 创建报告获取作业列表 
  listByProject(data){
    return get("/quest/front/listByProject",data)
  }
};

// 默认全部倒出
export default project;

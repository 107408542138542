// 管理员身份页面
// 报告
const reportEntrance = () => import("../views/manager/report/index.vue"); //报告入口
const createReport = () => import("../views/manager/report/create_report.vue"); // 创建报告
const editReport = () => import("../views/manager/report/edit_report.vue"); // 编辑报告
const queryReport = () => import("../views/manager/report/query_report.vue"); // 查看报告
const KimiCreate = () => import("../views/manage/manage_gsw/create_kimi.vue") // 创建kimi
const KimiView = () => import("../views/manage/manage_gsw/view_kimi.vue") // 查看kimi

// 观思问
const queryObserve = () => import("../views/manager/observe-detail.vue"); // 观思问查看

export default [
    {
        path: "/manager/report",
        name: "managerReport",
        component: reportEntrance,
        meta: {
            title: "报告管理",
            keepAlive: true
        },
        children: [{
            path: "create/:id",
            name: "createReport",
            component: createReport,
            meta: {
                title: "创建报告",
                bread: true,
                keepAlive: false
            }
        }, {
            path: "edit/:id",
            name: "editReport",
            component: editReport,
            meta: {
                title: "编辑报告",
                bread: true,
                keepAlive: false
            }
        }, {
            path: "query/:id",
            name: "queryReport",
            component: queryReport,
            meta: {
                title: "查看报告",
                bread: true,
                keepAlive: false
            }
        }, {
            path: 'create_kimi',
            name: 'create_kimi',
            component: KimiCreate,
            meta: {
                title: "创建报告",
                bread: true,
                keepAlive: false
            },
        },
        {
            path: 'view_kimi',
            name: 'view_kimi',
            component: KimiView,
            meta: {
                title: "报告",
                bread: true,
                keepAlive: false
            },
        }]
    },
    {
        path: "/observe/:id",
        name: "queryObserve",
        component: queryObserve,
        meta: {
            title: "观思问管理",
            bread: true,
            keepAlive: true
        },
    }];
